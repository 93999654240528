import React, { useEffect, useState } from 'react';
import { useUser } from './UserContext'; // Import the useUser hook
import { fetchUserProfile } from './services/userService'; // Adjust the path as needed

const Dashboard = () => {
  const { logout } = useUser(); // Destructure logout from the context
  const [userProfile, setUserProfile] = useState(null); // State to store the user profile

  // Use the fetchUserProfile function from userService
  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const profile = await fetchUserProfile();
        setUserProfile(profile.user); // Extract the user object from the response
      } catch (error) {
        console.error('Error fetching user profile:', error);
        // Handle error (e.g., redirecting to login)
      }
    };

    getUserProfile();
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      // Optionally, navigate user to the login page after logging out
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div>
      <h1>Welcome to the Dashboard</h1>
      {userProfile ? (
        <div>
          <p>Name: {userProfile.username}</p>
          <p>Email: {userProfile.email}</p>
          <p>Role: {userProfile.role}</p>
          {/* You can display more user profile information here */}
        </div>
      ) : (
        <p>Loading profile...</p>
      )}
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Dashboard;
