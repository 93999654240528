// Create an axios instance
import axios from 'axios';

// Create an axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_AUTH_BASE_URL, // Your API base URL
  // Add common headers here if necessary, like your internal API key
  headers: {
    'X-Internal-API-Key': process.env.REACT_APP_INTERNAL_API_KEY, // Assuming you store your internal API key in .env
  },
  withCredentials: true,
});

// Axios response interceptor
api.interceptors.response.use(
  response => response, // this is for all successful responses
  async error => {
    const originalRequest = error.config;

    // Check for 401 Unauthorized response
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        // Make a request to your 'refresh token' endpoint
        const res = await api.post('/auth/token', { refreshToken });

        const { accessToken } = res.data;
        localStorage.setItem('authToken', accessToken); // Update the access token in localStorage

        // Update the header for the original request
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;

        // Return the original request with the new access token
        return api(originalRequest);
      } catch (refreshError) {
        // Handle failed refresh here (e.g., redirect to login)
        // You may want to clear tokens from localStorage and redirect to login
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

// Function to fetch user profile
const fetchUserProfile = async () => {
  try {
    const response = await api.get('/profile');
    // Assuming the response data structure is { data: userProfileInfo }
    return response.data; // or handle data as needed
  } catch (error) {
    console.error('Error fetching user profile:', error);
    // Handle error appropriately
    throw error; // Rethrowing the error to be handled by the caller
  }
};

export { fetchUserProfile };
