import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './UserContext'; // Modify according to your context usage

const PrivateRoute = ({ children }) => {
  const { user, loading } = useUser(); // Extracting loading state

  // Handling the loading state - Optionally render a loader or nothing
  if (loading) {
    return <div>Loading...</div>;
  }

  // Determining authentication status
  const isAuthenticated = user?.isAuthenticated ?? false;

  // Conditional rendering based on authentication status
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
