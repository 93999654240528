import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL;

  const login = async (formData) => {
    try {
      setError(null);
      await axios.post(`${authBaseUrl}/auth/login`, formData, { withCredentials: true });
      setUser({ isAuthenticated: true });
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed', error);
      setError('Login failed: Unexpected error');
    }
  };

  const logout = async () => {
    try {
      setError(null);
      await axios.post(`${authBaseUrl}/auth/logout`, {}, { withCredentials: true });
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
      setError('Failed to log out: Unexpected error');
    }
  };

  useEffect(() => {
    // Avoid automatic logout on the login page
    if (window.location.pathname !== '/login') {
      // Check the user authentication status from the server
      // Example: GET request to a route that validates user session
      // axios.get(`${authBaseUrl}/validateSession`, { withCredentials: true })
      // .then(() => setUser({ isAuthenticated: true }))
      // .catch(() => logout());
      // For now, just setting loading to false
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, error, login, logout, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
